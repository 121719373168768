/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = () => {
  if (window.__navigatingToLink === true) {
    return [0, 0];
  }
  return true;
};

exports.onRouteUpdate = () => {
  window.__navigatingToLink = false;
};

exports.onInitialClientRender = () => {
  require('typeface-heebo');
  require('typeface-montserrat');
  require('typeface-raleway');
  require('typeface-slabo-27px');
};
